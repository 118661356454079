.main {
  min-height: 100vh;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px black;
  background: url("../../static/images/brickwall.jpg") repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-weight: bold;
  padding: 40px 0 40px 0;
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 600px;
    width: 600px;
    font-size: large;
    .formSection {
      display: flex;
      flex-direction: column;
      margin: 20px;
      background-color: rgb(88, 172, 197);
      color: black;
      border: 2px solid black;
      .formEmail {
        height: 20px;
        padding: 5px;
        font-size: 15px;
        font-family: "Open Sans", sans-serif;
        color: black;
      }
      .formMessage {
        height: 200px;
        padding: 5px;
        font-size: 15px;
        font-family: "Open Sans", sans-serif;
        color: black;
        resize: none;
      }
    }
  }
  img {
    object-fit: scale-down;
    height: 500px;
    margin: 15px;
    border: 1px solid black;
  }
}

@media (min-width: 320px) and (max-width: 1029px) {
  .main {
    height: 100%;
    width: auto;
    justify-content: space-between;
    flex-direction: column;
    .form {
      height: auto;
      width: auto;
      margin: 50px 0 0 0;
    }
    img {
      height: 400px;
    }
  }
}
