.taleOfTwoProphets {
  width: 100%;
  display: grid;
  overflow-x: hidden;
  justify-items: center;
  border-bottom: solid 1px black;
  background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../static/images/arid-mountains.jpg") center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .imageAndBlurb {
    display: flex;
    align-items: center;
    justify-content: center;
    .imageAndButton {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      margin-bottom: 50px;
      img {
        height: 500px;
        border: 1px solid black;
        margin-bottom: 15px;
      }
    }
    .blurb {
      h1 {
        margin: 0;
      }
      max-width: 500px;
      font-size: 20px;
      margin-left: 50px;
      padding: 15px;
      border: 1px solid black;
      background-color: rgba(0, 0, 0, 0.456);
      color: white;
      .blurbText {
        font-style: italic;
      }
    }
  }
  .quotes {
    font-size: 18px;
    color: black;
    border: 1px solid black;
    background-color: #4a99e8ae;
    max-width: 800px;
    padding: 15px;
    margin: 30px;
    .author {
      font-style: oblique;
      font-weight: bolder;
    }
  }
  .videoParent {
    .iframe {
      width: 600px;
      height: 350px;
      margin-bottom: 35px;
    }
  }
}

@media (min-width: 320px) and (max-width: 1060px) {
  .taleOfTwoProphets {
    display: flex;
    flex-direction: column;
    align-items: center;
    .imageAndBlurb {
      flex-direction: column;
      .blurb {
        font-size: 18px !important;
        margin-left: 15px !important;
        margin-right: 15px !important;
      }
    }
    .quotes {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
    .iframe {
      width: 92% !important;
      height: 280px;
    }
  }
}
