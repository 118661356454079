.main {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px black;
  background: url("../../static/images/brickwall.jpg") repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .bio {
    h1 {
      margin: 0;
      font-size: 30px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    height: 450px;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid black;
    max-width: 890px;
    box-sizing: border-box;
  }
  img {
    height: 450px;
    margin: 15px;
    border: 1px solid black;
  }
}

@media (min-width: 320px) and (max-width: 1022px) {
  .main {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 40px 0 40px 0;
    .bio {
      width: 90%; 
      margin: 15px 0 15px 0;
      height: auto;
    }
    img {
      object-fit: scale-down;
      height: 300px;
    }
  }
}