.main {
  border-bottom: solid 1px black;
  min-height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../static/images/christmas-brickwall.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  .audio {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    font-size: larger;
    .audioColumns {
      display:flex;
      .audioFile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 15px;
        border: 2px solid black;
        background-color: #f1f3f4;
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 1029px) {
  .main {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .audioColumns {
      flex-direction: column;
    }
  }
}

@media (min-width: 320px) and (max-width: 599px) {
  .main {
    height: 100%;
    width: auto;
    flex-direction: column;
    .audioColumns {
      flex-direction: column;
    }
  }
}
