.header {
  background: url("../../static/images/books.png") no-repeat fixed bottom;
  color: black;
  border: solid 1px black;
  box-sizing: border-box;
  height: 175px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Nothing You Could Do", cursive;
  width: 100%;
  .links {
    display: flex;
    justify-content: space-evenly;
    .icons {
      height: 30px;
      width: auto;
      color: black;
      margin: 15px 15px 0 15px;
      &:hover {
        cursor: pointer;
        color: white;
      }
    }
  }
  .title {
    font-size: 70px;
    margin: 0 0 15px 0;
  }
}

@media (min-width: 768px) and (max-width: 1029px) {
  .header {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 320px) and (max-width: 599px) {
  .header {
    width: 100%;
    height: 120px;
    .title {
      font-size: 40px;
    }
  }
}
