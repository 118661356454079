.navbar {
  display: table;
  border-bottom: solid 1px black;
  width: 100%;
  text-decoration: none;
  background-color: white;
  font-size: large;
  .bars {
    display: none;
  }
  .unclickedLink {
    vertical-align: middle;
    color: #ffb329;
    background-color: rgb(66, 66, 66);
    border: 0.5px solid black;
    display: table-cell;
    text-decoration: none;
    width: 10%;
    padding: 5px;
    &:hover {
      cursor: pointer;
      background-color: #ffb329;
      color: black;
    }
    &:active {
      font-weight: bold;
    }
  }
  .clickedLink {
    vertical-align: middle;
    background-color: #ffb329;
    color: black;
    border: 0.5px solid black;
    display: table-cell;
    text-decoration: none;
    width: 10%;
    padding: 0 10px 0 10px;
    &:active {
      font-weight: bold;
    }
  }
}

.topbar {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px black;
  width: 100%;
  text-decoration: none;
  background-color: #ffa500;
  .bars {
    margin: 10px;
  }
  .clickedLink {
    background-color: #ffb329;
    color: black;
    border: 0.5px solid black;
    text-decoration: none;
    font-weight: bold;
    width: auto;
    height: 45px;
    padding: 0 10px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000 !important;
    &:active {
      font-weight: bolder;
    }
  }
  .unclickedLink {
    background-color: rgb(66, 66, 66);
    color: #ffb329;
    border: 0.5px solid black;
    text-decoration: none;
    font-weight: bold;
    width: auto;
    height: 45px;
    padding: 0 10px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000 !important;
    &:active {
      font-weight: bolder;
    }
  }
}

@media (min-width: 320px) and (max-width: 950px) {
  .navbar {
    background-color: rgb(66, 66, 66);
    color: #ffb329;
    display: flex;
    .bars {
      display: flex;
      justify-content: center;
      margin: 13px;
      width: 100%;
    }
    .unclickedLink {
      display: none;
    }
    .clickedLink {
      display: none;
    }
  }
}
