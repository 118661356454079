.button {
    width: auto;
    padding: 10px 15px 10px 15px;
    height: auto;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    color: black;
    border: 2px solid black;
    background-color: #FFB329;
    &:hover {
        cursor: pointer;
        background-color: #d29421;
    }
}