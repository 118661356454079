.jonah {
  display: grid;
  overflow-x: hidden;
  justify-content: center;
  border-bottom: solid 1px black;
  background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../static/images/space.jpg") center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .imageAndBlurb {
    display: flex;
    align-items: center;
    justify-content: center;
    .imageAndButton {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      margin-bottom: 50px;
      img {
        height: 500px;
        border: 1px solid black;
        margin-bottom: 15px;
      }
    }
    .description {
      h1 {
        margin: 0;
      }
      max-width: 500px;
      font-size: 20px;
      margin-left: 50px;
      padding: 15px;
      border: 1px solid black;
      background-color: rgba(0, 0, 0, 0.456);
      color: white;
    }
  }
  .videoParent {
    .iframe {
      width: 600px;
      height: 350px;
      margin-bottom: 35px;
    }
  }
}

@media (min-width: 320px) and (max-width: 1060px) {
  .jonah {
    display: flex;
    flex-direction: column;
    align-items: center;
    .imageAndBlurb {
      flex-direction: column;
      .description {
        font-size: 18px !important;
        margin-left: 15px !important;
        margin-right: 15px !important;
      }
    }
    .iframe {
      width: 92% !important;
      height: 280px;
    }
  }
}
