.home {
  width: 100%;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px black;
  background: url("../../static/images/brickwall.jpg") repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .homeInfo {
    font-size: 30px;
    background-color: rgba(66, 66, 66, 0.365);
    border-bottom: 5px solid rgb(66, 66, 66);
    h1 {
      margin: 0;
      padding: 20px;
    }
    .homeSection {
      display: flex;
      align-items: center;
      justify-content: center;
      h3 {
        margin: 15px;
      }
      h4 {
        font-size: 25px;
        margin: 0;
        max-width: 500px;
      }
      .authorInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px;
        img {
          object-fit: scale-down;
          height: 350px;
          border: 1px solid black;
        }
        .retelling {
          font-size: 18px;
          margin-bottom: 15px;
        }
      }
      .upcomingBook {
        margin: 50px;
        img {
          height: 500px;
          margin: 15px;
          border: 1px solid black;
          &:hover {
            box-shadow: 0 0 15px 10px grey;
            background-size: cover;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .worksection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    h1 {
      margin: 0;
      padding: 20px;
    }
    .works {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-bottom: 15px;
      p {
        max-width: 75%;
      }
      img {
        object-fit: scale-down;
        height: auto;
        max-height: 375px;
        border: 1px solid black;
        margin: 30px;
        &:hover {
          box-shadow: 0 0 15px 10px grey;
          background-size: cover;
          transform: scale(1.1);
        }
      }
      .workOrderNormal {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      .workOrderReverse {
        display: flex;
        justify-content: space-around;
        flex-direction: row-reverse;
        align-items: center;
      }
    }
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .authorInfo {
    margin-right: 70px;
  }
  .works {
    width: 100% !important;
    p {
      max-width: 90% !important;
    }
    .workOrderNormal {
      flex-direction: column;
    }
    .workOrderReverse {
      flex-direction: column !important;
    }
  }
}

@media (min-width: 320px) and (max-width: 799px) {
  img {
    max-height: 375px;
  }
  .homeSection {
    flex-direction: column;
    h4 {
      margin: 0 15px 0 15px !important;
    }
    .upcomingBook {
      margin: 0 !important;
    }
    .authorInfo {
      .retelling {
        margin: 15px !important;
      }
    }
  }
  .works {
    width: 100% !important;
    img {
      margin: 0 !important;
    }
    p {
      max-width: 90% !important;
      margin: 15px 0 40px 0 !important;
    }
    .workOrderNormal {
      flex-direction: column;
    }
    .workOrderReverse {
      flex-direction: column !important;
    }
  }
}
