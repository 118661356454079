.footer {
  background: url("../../static/images/books.png") no-repeat fixed center;
  height: auto;
  font-size: 15px;
  text-align: center;
  vertical-align: middle;
  color: black;
  font-weight: bold;
  p {
    margin: 0;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
  }
}
