.main {
    height: 100vh;
    width: 100%;
    border-bottom: solid 1px black;
    background: url("../../static/images/brickwall.jpg") repeat center center;
    .text {
        display: flex;
        align-items: center;
        font-size: larger;
        flex-direction: column;
        padding: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1029px) {
    .main {
        min-height: 1170px;
    }
}